<template>
  <div class="app">
    <img
      class="app-logo"
      :class="{
        '-hidden': $route.name === 'Thanks'
      }"
      src="@/assets/img/logo-labuznicek.png"
      alt="Labužníček logo"
    >
    <div
      v-if="['Intro', 'Rate', 'Thanks'].includes($route.name)"
      class="logo-bar"
      :class="{
        '-centered': $route.name === 'Intro',
      }"
    >
      <img src="@/assets/img/logo-msprudy.png" alt="">
      <img src="@/assets/img/logo-kuzelna.png" alt="">
    </div>
    <div
      v-if="['Intro', 'Rate', 'Thanks'].includes($route.name) && mainStore.hasLocation"
      class="user-bar"
      :class="{
        '-white': $route.name === 'Thanks',
      }"
    >
      <div class="user-bar-item">
        {{ mainStore.location.name }}
      </div>
      <a
        href="#"
        @click.prevent="logout()"
        class="user-bar-item"
      >
        <span>Odhlásiť&nbsp;sa</span>
        <svg class="user-bar-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22"><g opacity=".8"><path fill="currentColor" d="M9 10.5a.5.5 0 0 0 0 1v-1Zm14.4.9c.1-.2.1-.6 0-.8l-3.2-3.1a.5.5 0 1 0-.7.7l2.8 2.8-2.8 2.8a.5.5 0 1 0 .7.7l3.2-3.1ZM9 11.5h14v-1H9v1Z"/><path stroke="currentColor" stroke-linecap="round" d="M17 6.3V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2v-3.3"/></g></svg>
      </a>
    </div>
    <router-view v-slot="{ Component }">
      <Transition name="slide" >
        <component
          :is="Component"
          :key="$route.path"
        />
      </Transition>
    </router-view>
  </div>
</template>

<script>
import '@/styles/main.scss';
import { mapStores } from 'pinia';
import { useMainStore } from '@/store/main';

export default {
  computed: {
    ...mapStores(useMainStore),
  },
  methods: {
    logout() {
      this.mainStore.logout();
      this.$router.push({ name: 'Intro' });
    },
  },
};
</script>

<style lang="scss" scoped>

.app {
}

.debug-bar {
  position: absolute;
  width: 200px;
  background: white;
  bottom: 6rem;
  right: 0;
  padding: 1rem;
}

.app-logo {
  position: absolute;
  z-index: 10;
  top: 20px;
  width: 220px;
  left: calc(50% - 110px);
  transition: opacity 0.7s ease-in-out;
  &.-hidden {
    opacity: 0;
  }
}

.logo-bar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  display: flex;
  padding: 1rem 1.5rem 1rem 1rem;
  gap: 1.5rem;
  background-color: rgba(240, 241, 245, 0.7);
  border-top-right-radius: 24px;
  img {
    display: block;
    height: 40px;
    width: auto;
  }
  &.-centered {
    left: 50%;
    transform: translateX(-50%);
  }
}

.user-bar {
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: var(--brown);
  padding: 1rem;
  transition: color 0.7s ease-in-out;
  .user-bar-item {
    color: inherit;
    text-decoration: none;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .user-bar-item + .user-bar-item {
    border-left: 1px solid currentColor;
  }
  .user-bar-icon {
    height: 20px;
  }
  &.-white {
    color: var(--off-white);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.7s ease-in-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
