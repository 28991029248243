<template>
  <div class="screen screen-intro">
    <form
      class="form"
      @submit.prevent="submit(selectedLocation)"
    >
      <div class="label">Prihláste sa</div>
      <div class="locations">
        <ButtonLocation
          v-for="location in locations"
          :key="location.id"
          :selected="selectedLocation === location.id"
          @click="selectedLocation = location.id"
        >
          {{ location.name }}
        </ButtonLocation>
      </div>
      <ButtonSubmit />
    </form>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMainStore } from '@/store/main';
import api from '@/api';

import ButtonSubmit from '@/components/ButtonSubmit.vue';
import ButtonLocation from '@/components/ButtonLocation.vue';

export default {
  components: {
    ButtonSubmit,
    ButtonLocation,
  },
  data: () => ({
    locations: [],
    selectedLocation: '',
  }),
  computed: {
    ...mapStores(useMainStore),
  },
  methods: {
    async load() {
      const response = await api.get('/locations');
      this.locations = response.data.locations;
    },
    submit(id) {
      this.mainStore.location.id = id;
      this.mainStore.location.name = this.locations.find((l) => l.id === id).name;
      this.$router.push({
        name: 'Rate',
        params: { meal: 'polievka' },
      });
    },
  },
  mounted() {
    this.load();
  },
};

</script>

<style lang="scss" scoped>
.screen-intro {
  background-color: var(--off-white);
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 1056px;
  }
  .label {
    margin-bottom: 3rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Loyola Next', sans-serif;
  }
  .locations {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem;
    margin-bottom: 3rem;
  }
}
</style>
