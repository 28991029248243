/* eslint-disable global-require */
const meals = [
  {
    key: 'polievka',
    name: 'Polievka',
    layout: {
      question: 'Chutila ti polievka?',
      image: require('@/assets/img/hlboky-tanier.png'),
      background: 'var(--yellow)',
    },
  },
  {
    key: 'hlavne',
    name: 'Hlavné',
    layout: {
      question: 'Chutilo ti hlavné jedlo?',
      image: require('@/assets/img/plytky-tanier.png'),
      background: 'var(--orange)',
    },
  },
];

export default meals;
