import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:8001/',
  // baseURL: 'https://labuznicek-backend.vizio.sk/',
  baseURL: 'https://backend.labuznicek.sk/',

  timeout: 5000,
});

export default api;
