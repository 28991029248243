<template>
  <button
    class="button-location"
    :class="{
      '-selected': selected,
    }"
    type="button"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    selected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.button-location {
  color: var(--brown);
  border: 3px solid currentColor;
  padding: 1rem 1.5rem;
  min-width: 12.5rem;
  text-align: center;
  border-radius: var(--border-radius);
  background-color: transparent;
  font-size: 2rem;
  cursor: pointer;
  &.-selected {
    color: var(--orange);
  }
}
</style>
