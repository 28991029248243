import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(pinia);
// eslint-disable-next-line no-alert
app.config.globalProperties.$alert = (msg) => alert(msg);

app.mount('#app');
