import { defineStore } from 'pinia';
import api from '@/api';

// eslint-disable-next-line import/prefer-default-export
export const useMainStore = defineStore('main', {
  state: () => ({
    location: {
      id: null,
      name: null,
    },
    rating: {
      polievka: null,
      hlavne: null,
    },
  }),
  getters: {
    hasLocation: (state) => !!state.location.id,
  },
  actions: {
    async submitRating() {
      const response = await api.post(`/rate/${this.location.id}/${this.rating.polievka}/${this.rating.hlavne}`);
      console.log(response);
    },
    logout() {
      this.rating.polievka = null;
      this.rating.hlavne = null;
      this.location.id = null;
      this.location.name = null;
    },
    reset() {
      this.rating.polievka = null;
      this.rating.hlavne = null;
    },
  },
});
