<template>
  <button
    class="button-smile"
    :class="[
      `-value-${value}`,
      after ? '-after' : null,
    ]"
    :aria-label="`Hodnotenie ${value}`"
    @click="handleClick"
  >
  </button>
</template>

<script>
/* eslint-disable global-require */
export default {
  data: () => ({
    after: false,
  }),
  props: {
    value: String,
  },
  emits: ['click'],
  mounted() {
    // this.preloadImages();
  },
  methods: {
    preloadImages() {
      const image1 = new Image();
      const image1after = new Image();
      const image2 = new Image();
      const image2after = new Image();
      const image3 = new Image();
      const image3after = new Image();

      image1.src = require('@/assets/img/smile-1.png');
      image1after.src = require('@/assets/img/smile-1.png');
      image2.src = require('@/assets/img/smile-2.png');
      image2after.src = require('@/assets/img/smile-2-after.png');
      image3.src = require('@/assets/img/smile-3.png');
      image3after.src = require('@/assets/img/smile-3-after.png');
    },
    handleClick() {
      this.after = true;
      setTimeout(() => {
        this.$emit('click');
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-smile {
  border: 0;
  width: 192px;
  height: 192px;
  background-color: transparent;
  border-radius: 50%;
  font-size: 32px;
  background-size: contain;
  &.-value-1 {
    background-image: url('@/assets/img/smile-1.png');
  }
  &.-value-1.-after {
    background-image: url('@/assets/img/smile-1-after.png');
  }
  &.-value-2 {
    background-image: url('@/assets/img/smile-2.png');
  }
  &.-value-2.-after {
    background-image: url('@/assets/img/smile-2-after.png');
  }
  &.-value-3 {
    background-image: url('@/assets/img/smile-3.png');
  }
  &.-value-3.-after {
    background-image: url('@/assets/img/smile-3-after.png');
  }
}
</style>
