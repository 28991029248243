<template>
  <button
    class="button-submit"
    type="submit"
  >
    <img class="check" src="@/assets/img/check.png" alt="">
  </button>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.button-submit {
  background-color: var(--yellow);
  border: 0;
  width: 280px;
  height: 88px;
  border-radius: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .check {
    display: block;
    height: 56px;
    width: auto;
  }
}

</style>
