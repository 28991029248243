<template>
  <div
    class="screen screen-thanks"
    @click="reset()"
  >
    <h1 class="title">Ďakujeme</h1>
    <img
      class="ok-hand"
      src="@/assets/img/ok-hand.png"
      alt=""
    >
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMainStore } from '@/store/main';

export default {
  mounted() {
    this.mainStore.submitRating();
    setTimeout(() => this.reset(), 2000);
  },
  computed: {
    ...mapStores(useMainStore),
  },
  methods: {
    reset() {
      this.mainStore.reset();
      this.$router.push({ name: 'Rate', params: { meal: 'polievka' } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.screen-thanks {
  background-color: var(--blue);
  color: var(--yellow);
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    @include title-font;
    font-size: 200px;
    margin-bottom: 0.8em;
  }
  .ok-hand {
    position: absolute;
    bottom: 0;
  }
}
</style>
