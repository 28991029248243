import { createRouter, createWebHistory } from 'vue-router';
import { useMainStore } from '@/store/main';

import IntroView from '@/views/IntroView.vue';
import RateView from '@/views/RateView.vue';
import ThanksView from '@/views/ThanksView.vue';
import StatsView from '@/views/StatsView.vue';

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: IntroView,
  },
  {
    path: '/hodnot/:meal',
    name: 'Rate',
    component: RateView,
    props: true,
    meta: { requiresLocation: true },
  },
  {
    path: '/dakujeme',
    name: 'Thanks',
    component: ThanksView,
    meta: { requiresLocation: true },
  },
  {
    path: '/stats',
    name: 'Stats',
    component: StatsView,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useMainStore();
  const {
    requiresLocation,
  } = to.meta;

  if (
    (requiresLocation && !store.hasLocation)
  ) {
    next({ name: 'Intro' });
  } else {
    next();
  }
});

export default router;
