<template>
  <div>
    <div class="label">
      {{ label }} ({{ total }})
    </div>
    <Vue3Popper
      :content="`
        Pozitívne hodnotenia ${counts['3']}.
        Neutrálne hodnotenia: ${counts['2']}.
        Negatívne hodnotenia: ${counts['1']}.
        Spolu: ${counts['1'] + counts['2'] + counts['3']}`"
      hover
    >
      <div class="rating-bar">
        <div class="positive" :style="{ flexGrow: counts['3'] }"></div>
        <div class="neutral" :style="{ flexGrow: counts['2'] }"></div>
        <div class="negative" :style="{ flexGrow: counts['1'] }"></div>
      </div>
    </Vue3Popper>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Vue3Popper from 'vue3-popper';

export default {
  components: {
    Vue3Popper,
  },
  props: {
    counts: Object,
    label: String,
  },
  computed: {
    total() {
      return Object.values(this.counts).reduce((acc, cv) => acc + cv, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-bar {
  width: 150px;
  height: 20px;
  background-color: #DDD;
  display: flex;
  .positive {
    background-color: #54872B;
  }
  .neutral {
    background-color: #CCC862;
  }
  .negative {
    background-color: #AA5134;
  }
}

.label {
  font-size: 12px;
}

.total-count {
  font-size: 12px;
}
</style>
