<template>
  <div class="stats-view">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
    <div class="container-fluid">
      <table class="table table-hover table-sm" v-if="rows.length">
        <thead>
          <tr>
            <th>Dátum</th>
            <template
              v-for="(location) in rows[0].locations"
              :key="location.location"
            >
              <th>
                {{ location.location }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            :key="row.date"
          >
            <th scope="row">
              {{ row.date }}
            </th>
            <template
              v-for="(location) in row.locations"
              :key="location.location"
            >
              <td>
                <RatingBar
                  label="Polievka"
                  :counts="location.ratingCounts.polievka"
                />
                <RatingBar
                  label="Hlavné"
                  :counts="location.ratingCounts.hlavne"
                />
              </td>
            </template>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import RatingBar from '@/components/RatingBar.vue';

export default {
  components: {
    RatingBar,
  },
  data: () => ({
    rows: [],
  }),
  mounted() {
    this.fetchRatings();
  },
  methods: {
    async fetchRatings() {
      const ratings = await api.get('/ratings');
      this.rows = ratings.data;
    },
  },
};
</script>

<style scoped>
.stats-view {
  padding-top: 7rem;
}
</style>
