<template>
  <div
    class="screen screen-rate"
    :style="{
      backgroundColor: currentMeal?.layout?.background,
    }"
  >
    <div class="buttons">
      <ButtonSmile
        @click="rate(3)"
        value="3"
        :key="meal + '3'"
      />
      <ButtonSmile
        @click="rate(2)"
        value="2"
        :key="meal + '2'"
      />
      <ButtonSmile
        @click="rate(1)"
        value="1"
        :key="meal + '1'"
      />
    </div>
    <div>
      <img
        :src="currentMeal.layout.image"
        alt="Tanier"
        class="image"
      >
    </div>
    <h1 class="question">{{ currentMeal.layout.question }}</h1>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useMainStore } from '@/store/main';

import ButtonSmile from '@/components/ButtonSmile.vue';
import meals from '@/data/meals';

export default {
  props: {
    meal: String,
  },
  components: {
    ButtonSmile,
  },
  computed: {
    ...mapStores(useMainStore),
    currentMeal() {
      return meals.find((meal) => meal.key === this.meal);
    },
  },
  methods: {
    rate(score) {
      this.mainStore.rating[this.meal] = score;
      if (this.meal === 'polievka') {
        this.$router.push({
          name: 'Rate',
          params: { meal: 'hlavne' },
        });
      }
      if (this.meal === 'hlavne') {
        this.$router.push({ name: 'Thanks' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.screen-rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6rem;
  .buttons {
    display: flex;
    justify-content: center;
    gap: 64px;
    margin-bottom: 88px;
  }
  .image {
    width: 264px;
    margin-bottom: 32px;
  }
  .question {
    @include title-font;
    font-size: 3rem;
  }
}
</style>
